import React from "react"
import { graphql } from "gatsby"
import windowSize from "react-window-size"

import Stars from "../../components/blog/stars"
import Layout from "../../layouts"
import HeroContainer from "../../components/containers/hero"
import Header from "../../components/header"
import Title from "../../components/hero/title"
import LinkToParentPage from "../../components/link-to-parent-page"
import SEO from "../../components/seo"
import PullBelowHeader from "../../components/containers/pull-below-header"

import { rhythm } from "../../utils/typography"
import { mediaQueries } from "../../utils/presets"
import { guideContainer, guidePaddingBottom } from "../../utils/styles"
import BlogPostPreviewItem from "../../components/blog/blog-post-preview-item"
import Footer from "../../components/footer"

const styles = {
  container: {
    overflowX: `hidden`,
    position: `relative`,
    zIndex: 2,
  },
}

const TagPage = ({ location, data: { wpTag: tag } }) => {
  const tagName = tag.name.charAt(0).toUpperCase() + tag.name.slice(1)
  return (
    <Layout pathname={location.pathname}>
      <SEO
        title={`${tagName} Blog Posts`}
        description={`All posts tagged as ${tagName} on the Gatsby blog.`}
        url={location.href}
      />
      <Header />
      <PullBelowHeader>
        <div
          css={{
            ...styles.container,
            ...guidePaddingBottom,
            position: `relative`,
          }}
        >
          <Stars />
          <HeroContainer isInverted>
            <div css={guideContainer}>
              <LinkToParentPage title="Return to All tags" link="/blog/tags/" />
              <Title
                customStyles={{
                  maxWidth: rhythm(16),
                  [mediaQueries.phablet]: {
                    maxWidth: rhythm(20),
                  },
                }}
              >
                {`${tag.posts.nodes.length} post${
                  tag.posts.nodes.length > 1 ? "s" : ""
                } tagged with "${tag.name}"`}
              </Title>
              {tag.posts?.nodes?.map(post => {
                return (
                  <BlogPostPreviewItem
                    post={post}
                    key={post.slug}
                    Heading="h2"
                  />
                )
              })}
            </div>
          </HeroContainer>
        </div>
      </PullBelowHeader>
      <Footer />
    </Layout>
  )
}

export default windowSize(TagPage)

export const pageQuery = graphql`
  query TagPage($id: String!) {
    wpTag(id: { eq: $id }) {
      id
      name
      slug
      posts {
        nodes {
          ...BlogPostPreviewInformation
        }
      }
    }
  }
`
